import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {DownloadOutlined, EditOutlined, DeleteOutlined, InfoOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Table, Form, Row, Col, Button } from 'react-bootstrap'; // Import Bootstrap components
import { ColorRing } from 'react-loader-spinner';
import '../../src/Userlist.css';
import { Pagination, Tooltip } from 'antd';
import { PDFDownloadLink, Document, Page, Text } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import { API_URL } from '../ApiUrl';
import { Popover, Input, Select } from 'antd';
import { FilterOutlined } from '@ant-design/icons';


function Coachlist() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [csvData, setCsvData] = useState([]);
  const [detailData, setDetailData] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [pdfContent, setPdfContent] = useState(null);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchQuery]);

  useEffect(() => {
    formatCsvData();
  }, [data]);

  const fetchData = async () => {
    try {
      // Replace the URL with your actual API endpoint
      const apiUrl = `${API_URL}/fetch-all-coaches?page=${currentPage}&limit=${itemsPerPage}&search=${searchQuery}`;
      const response = await fetch(apiUrl);
      const result = await response.json();
      console.log(result.data, "result ka data hai")

      if (response.ok) {
        setData(result.data);

      } else {
        console.error('Failed to fetch data:', result.error);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  const formatCsvData = () => {
    const formattedData = data.map(row => ({
      "First Name": row.first_name,
      "Last Name": row.last_name,
      "Specializations": row.specializations,
      "Location": row.location,
      
      "Experience(yr)": row.experience,
      "Status": row.status ? "Active" : "Inactive"
    }));

    setCsvData(formattedData);
  };


  const generatePdfContent = (rowData) => (
    <Document>
      <Page>
        <Text>First Name: {rowData.first_name}</Text>
        <Text>Last Name: {rowData.last_name}</Text>
        <Text>Gender: {rowData.gender}</Text>
        <Text>Age: {rowData.age}</Text>
        <Text>Mobile Number: {rowData.mobile}</Text>
        <Text>Available: {rowData.availability}</Text>
        <Text>specializations: {rowData.Specializations}</Text>
        <Text>Language: {rowData.language}</Text>
        <Text>Experience: {rowData.experience}</Text>
        <Text>Address: {rowData.address}</Text>
        <Text>State: {rowData.state}</Text>
        <Text>City: {rowData.city}</Text>
        <Text>Zipcode: {rowData.zipcode}</Text>
      </Page>
    </Document>
  );


  const handleColumnFilter = (columnName, value) => {
    setCurrentPage(1); 
    setSearchQuery(''); 
    if (columnName === 'status') {
      if (value === 'all') {
        fetchData();
      } else {
        const filteredData = data.filter((row) => {
          if (value === 'active') {
            return row.status === true;
          } else if (value === 'inactive') {
            return row.status === false;
          }
          return true; 
        });
        setData(filteredData);
      }
    } 
    else {
      // Handle filtering for other columns if needed
      const filteredData = data.filter((row) =>
        row[columnName].toLowerCase().includes(value.toLowerCase())
      );
      setData(filteredData);
    }

    setIsPopoverOpen(false); // Close popover after filtering
  };

  const { Option } = Select;  

  const handlePdf = async (row) => {
    console.log('Info clicked for row:', row);
    try {
      // Fetch detail data
      const detailResponse = await fetch(`${API_URL}/fetch-coach/${row._id}`);
      const detailResult = await detailResponse.json();
      if (detailResponse.ok) {
        setDetailData(detailResult.data);
        setPdfContent(generatePdfContent(detailResult.data));
      } else {
        console.error('Failed to fetch detail data:', detailResult.error);
      }
    } catch (error) {
      console.error('Error fetching detail data:', error);
    }
  };


  const handleInfo = async (row) => {
    console.log('Info clicked for row:', row);
    try {
      // Fetch detail data
      const detailResponse = await fetch(`${API_URL}/fetch-coach/${row._id}`);
      const detailResult = await detailResponse.json();
      if (detailResponse.ok) {
        setDetailData(detailResult.data);
      } else {
        console.error('Failed to fetch detail data:', detailResult.error);
      }
    } catch (error) {
      console.error('Error fetching detail data:', error);
    }
  };

  const handleEdit = async (row) => {
    console.log('Edit clicked for row:', row);
    try {
      const response = await fetch(`${API_URL}/update-coach/${row._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      });

      if (response.ok) {
        console.log('Category name updated successfully');
      } else {
        const responseData = await response.json();
        console.error('Failed to update category name:', responseData.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Error updating category name:', error);
    }
  };

  const handleDelete = async (row) => {
    try {
      const apiUrl = `${API_URL}/delete-coach/${row._id}`;

      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        Swal.fire('Deactivated!', 'Your Profile has been Inactivated.', 'success');
        // Update your state or refetch data to reflect the deletion
        fetchData();
      } else {
        console.error('Failed to delete category:', response.statusText);
        Swal.fire('Error', 'Failed to delete category.', 'error');
      }
    } catch (error) {
      console.error('Error deleting category:', error);
      Swal.fire('Error', 'An error occurred while deleting the category.', 'error');
    }
  };

  const handleSearch = () => {
    setSearchQuery(searchText);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handlePagination = (page, pageSize) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data
    .filter((row) => row.first_name.toLowerCase().includes(searchText.toLowerCase()))
    .slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <h3 className="mb-4 title">Coach</h3>
      <div className="cnt">
        <Form.Group as={Row} className="mb-3">
          <Col xs={12} sm={6}>
            <Form.Control
              type="text"
              className="search-input"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchInputChange}
            />
          </Col>
          <Col sm={6} className="d-flex justify-content-end">
            <Link to="/coaches/add">
              <button className="add-button mr-2">Add Coach</button>
            </Link>
            <CSVLink data={csvData} filename={"user_list.csv"}>
                <button 
                  className="down-button"
                >
                  Download 
                </button>
              </CSVLink>
          </Col>
        </Form.Group>
        <div className="table-container">
          {loading ? (
            <div className="text-center">
              <ColorRing
                visible={true}
                height="50"
                width="50"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
              />
              <p>Loading...</p>
            </div>
          ) : (
            <Table className='custom-table'>
              <thead>
                <tr>
                  <th style={{ width: '7%' }}>S.No.</th>
                  <th style={{ width: '10%' }}>
                  First Name{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by First Name"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('first_name', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>
                  <th style={{ width: '10%' }}>
                  Last Name{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Last Name"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('last_name', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>

                  </th>
                  <th style={{ width: '14%' }}>
                  Mobile Number{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Mobile Number"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('mobile', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>
                  <th style={{ width: '10%' }}>
                  Location{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Location"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('location', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>

                  </th>
                  <th style={{ width: '10%' }}>
                  Specialization{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Specializations"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('specializations', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>

                  </th>
                  <th style={{ width: '10%' }}>
                  Experience(yr){' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Experience(yr)"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('experience', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>

                  </th>
                  <th style={{ width: '10%' }}>
                    Status{' '}
                    <Popover
                      placement="bottom"
                      content={
                        <Select
                          placeholder="Select status"
                          onChange={(value) => handleColumnFilter('status', value)}
                          style={{ width: 190 }}
                        >
                          <Option value="all">All</Option>
                          <Option value="active">Active</Option>
                          <Option value="inactive">Inactive</Option>
                        </Select>
                      }
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>
                  <th style={{ width: '15%' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((row, index) => (
                  <tr key={row._id}>
                    <td>{index + 1 + indexOfFirstItem}</td>
                    <td>{row.first_name}</td>
                    <td>{row.last_name}</td>
                    <td>{row.mobile}</td>
                    <td>{`${row.location.address}, ${row.location.city}, ${row.location.state}`}</td>
                    <td>{row.specializations.join(', ')}</td>
                    <td>{row.experience}</td>
                    <td style={{ color: row.status ? "#4fd104" : "#ff0000", fontWeight: "bold" }}>
                    {row.status ? "Active" : "Inactive"}
                    </td>
                    <td>
                    <div style={{ display: 'flex' }}>

                      <PDFDownloadLink
                            document={generatePdfContent(row)}
                            fileName={`coachlist_details.pdf`}
                            style={{marginRight: '5%'}}
                          >
                            <DownloadOutlined
                              style={{
                                fontSize: '20px',
                                color: '#fcfcfa',
                                borderRadius: '5px',
                                padding: '5px',
                                backgroundColor: '#ff5f15',
                              }}
                              onClick={() => handlePdf(row)}
                            />
                          </PDFDownloadLink>

                      <Tooltip 
                      title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                        {`Full Name: ${row.first_name} ${row.last_name}\nGender: ${row.gender}\nAge: ${row.age}\nEmail: ${row.email}\nMobile No: ${row.mobile}\nAvailability: ${row.availability}\nSpecializations: ${row.specializations}\nBio: ${row.bio}`}
                        </span>
                      }
                      arrow
                        >
                            <InfoOutlined
                              style={{
                                fontSize: '20px',
                                color: '#fcfcfa',
                                borderRadius: '5px',
                                padding: '5px',
                                backgroundColor: '#ff5f15',
                                cursor: 'pointer', // Add cursor
                                marginRight: '5%'
                              }}
                              onClick={() => handleInfo(row)}
                            />
                          </Tooltip>
                        <Link to={`/coaches/edit/${row._id}`} style={{ marginLeft: '1%' }}>
                          <EditOutlined
                            style={{
                              fontSize: '20px',
                              color: '#fcfcfa',
                              borderRadius: '5px',
                              padding: '5px',
                              backgroundColor: '#3d9c06',
                            }}
                            onClick={() => handleEdit(row)}
                          />
                        </Link>
                        <DeleteOutlined
                          style={{
                            fontSize: '20px',
                            color: '#E7F3FF',
                            borderRadius: '5px',
                            padding: '5px',
                            backgroundColor: '#ff5f15',
                            marginLeft: '5px',
                          }}
                          onClick={() => handleDelete(row)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
        {/* <div className="pagination-container">
          <ul className="pagination">
            {Array.from({ length: Math.ceil(data.length / itemsPerPage) }).map((_, index) => (
              <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePagination(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </div> */}
        <Pagination
  pageSizeOptions={["5", "10", "20", "50"]} // Available page sizes
  showSizeChanger={true} // Show the page size changer dropdown
  showQuickJumper={true} // Show quick jumper
  total={data.length} // Total number of items
  pageSize={itemsPerPage} // Items per page
  current={currentPage} // Current page
  onChange={handlePagination} 
  onShowSizeChange={(current, size) => {
    setCurrentPage(1); // Reset to first page when changing page size
    setItemsPerPage(size); // Update items per page
  }}
/>
      </div>
    </>
  );
}

export default Coachlist;
