import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { StateSelect, CitySelect } from "react-country-state-city";
import { useParams, useNavigate } from "react-router-dom";
import { FiUpload, FiX } from 'react-icons/fi';
import Swal from "sweetalert2";
import axios from "axios";
import { API_URL } from '../ApiUrl';
import { Image_URL } from '../ApiUrl';
import '../Coaches.css'

const UpdateCoach = () => {
  const { _id } = useParams();
  const navigate = useNavigate();

  const [input, setInput] = useState({
    first_name: "",
    last_name: "",
    email: "",
    experience: "",
    availability: "",
    specializations: "",
    bio: "",
    venue_rules: "",
    profile_picture:[],
    status: "",
  });


  const [newFile, setNewFile] = useState({ new_images: [] });
  console.log(newFile, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>> newFile");
  const [filePreview, setFilePreview] = useState();

  useEffect(() => {
    const fetchcoach = async () => {
      try {
        const response = await axios.get(`${API_URL}/fetch-coach/${_id}`);
        const coach = response.data;
        console.log(response.data, "Data")
        setInput({
          first_name: coach.coach.first_name,
          last_name: coach.coach.last_name,
          email: coach.coach.email,
          experience: coach.coach.experience,
          availability: coach.coach.availability,
          specializations: coach.coach.specializations.join(', '),
          bio: coach.coach.bio,
          profile_picture: coach.coach.profile_picture,
          status: coach.coach.status,
          // venue_rules: coach.coach.venue_rules,
        });
        if(response.data.coach.profile_picture.length > 0){
          const imageUrl = response.data.coach.profile_picture; // Assuming only one image is allowed
          setFilePreview(imageUrl); // Set filePreview with the image URL
        }
      }catch (error) {
        console.error("Error fetching coach data:", error);
      }
    };

    fetchcoach();
  }, []);

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    setNewFile(prevState => ({
      ...prevState,
      new_images: [...prevState.new_images, ...files],
    }));
  };

  const handlePreviousImages = (e) => {
    const files = Array.from(e.target.files);
    setNewFile(prevState => ({
      ...prevState,
      profile_picture: [...prevState.profile_picture, ...files],
    }));
  };


  const handleRemovePreviousImages = (ele) => {
    setInput(prevState => ({
      ...prevState,
      profile_picture: prevState.profile_picture.filter((_, i) => i !== ele),
    }));
  };

  

  const handleRemovePhoto = (index) => {
    setNewFile(prevState => ({
      ...prevState,
      new_images: prevState.new_images.filter((_, i) => i !== index),
    }));
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
    console.log("Form Data Updated:", input);
  };


  const uploadImage = async (fileArray) => {
    console.log('data before sending..................', fileArray)
    try {
      // formData.append("uploadFile", image);
      const formData = new FormData();
      fileArray.forEach((newFile, index) => {
        formData.append(`uploadFile`, newFile); // Append each file directly
      });
      const response = await axios.post(
        `${API_URL}/upload-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      // console.log(response, "response")
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return null;
    }
  };

  const handleCancel = () => {
    navigate('/coaches');
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const uploadResponses = await uploadImage(newFile.new_images);
      if (uploadResponses) {
        const formData = new FormData();
        console.log(uploadResponses.data.file_data, "--------------------indexed imag urls---------------------------")
        uploadResponses.data.file_data.map((ele) => {
          console.log(ele, "all eleeeeeeee")
          formData.append('profile_picture', ele);
        })
      const response = await axios.put(
        `${API_URL}/update-coach-super-admin/${_id}`,
        input
      );
      console.log("API Response:", response.data);
      Swal.fire({
        icon: "success",
        title: "Updated!",
        text: "Your form data has been updated successfully.",
      }).then((result) => {
        navigate("/coaches");
      });
    } }catch (error) {
      console.error("API Error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to update form. Please try again later.",
      });
    }
  };


  return (
    <>
    <h3 className="mb-4 title">Update Coach</h3>
    <Container>
      <Form>
        <Row>
          <Col sm={4}>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                name="first_name"
                value={input.first_name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter last name"
                name="last_name"
                value={input.last_name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={input.email}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          
        </Row>
        <Row>
        
          <Col sm={4}>
          <Form.Group controlId="formSpecializations">
              <Form.Label>Specializations</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter specializations"
                name="specializations"
                value={input.specializations}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
          <Form.Group controlId="formExperience">
              <Form.Label>Experience(in years)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter experience"
                name="experience"
                value={input.experience}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group controlId="formAvailability">
              <Form.Label>Availability</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter availability"
                name="availability"
                value={input.availability}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

        </Row>
        <Row>
          <Col sm={4}>
            <Form.Group controlId="formBio">
              <Form.Label>Bio</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter bio"
                name="bio"
                value={input.bio}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col sm={4}>
          <div className="mb-3">
          <h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Upload Profile Picture</h6>
          <div
            onDrop={(e) => {
              e.preventDefault();
              const files = Array.from(e.dataTransfer.files);
              setInput(prevState => ({
                ...prevState,
                profile_picture: [...prevState.profile_picture, ...files.filter(file => file.type.startsWith('image/'))],
              }));
            }}
            onDragOver={(e) => e.preventDefault()}
            style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center', width: '300px' }}
          >
            <h3 style={{ fontSize: '18px' }}>Drag & Drop here</h3>
            <div style={{ marginBottom: '10px' }}>
              <FiUpload style={{ fontSize: '48px', marginBottom: '10px' }} />
              <input type="file" onChange={handleFileInputChange} style={{ display: 'none' }} />
              <button className='btn3' onClick={() => document.querySelector('input[type=file]').click()}> Or Click to Select </button>
            </div>
            <div>

              {filePreview && (
                <div>
                  {filePreview.map((ele, index) => {
                    return (
                      <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                        {/* {console.log(`http://localhost:4000/${ele.src}`, "imgggggggggg")} */}
                        <img src={`http://localhost:4000/${ele.src}`} alt="" style={{ width: '100px', height: '100px', margin: '5px' }} />
                        <button
                          onClick={() => {
                            handleRemovePreviousImages(index)
                            setFilePreview(() => filePreview.filter((ele2) => {
                              return ele2 !== ele;
                            }));
                          }}
                          style={{ position: 'absolute', top: '5px', right: '5px', background: 'none', border: 'none', cursor: 'pointer' }}
                        >
                          <FiX />
                        </button>
                      </div>
                    )
                  })
                  }
                </div>
              )}
              {newFile.new_images.map((photo, index) => (
                <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                  <img src={URL.createObjectURL(photo)} alt={`Photo ${index}`} style={{ width: '100px', height: '100px', margin: '5px' }} />
                  <button
                    onClick={() => handleRemovePhoto(index)}
                    style={{ position: 'absolute', top: '5px', right: '5px', background: 'none', border: 'none', cursor: 'pointer' }}
                  >
                    <FiX />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        </Col>


          <Form.Group controlId="formCheckbox">
          <div className="checkbox-container">
            <Form.Check
              type="checkbox"
              id="statusCheckbox"
              name="status"
              aria-label="option 1"
              className="checkbox-input"
              checked={input.status || false}
              onChange={e => setInput({ ...input, status: e.target.checked })}
            />
          </div>
          <Form.Label className="checkbox-label">Status</Form.Label>
        </Form.Group>

        </Row>
        <button type="submit" className="SubmitButton"  onClick={handleSubmit}>
          Update
        </button>

        <button type="cancel" className="CancelButton" onClick={handleCancel}>
            Cancel
          </button>
      </Form>
    </Container>
    </>
  );
};

export default UpdateCoach;
