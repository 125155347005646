import React, { useState, useEffect } from 'react';
import { Table, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Tooltip, Pagination } from 'antd';
import { EditOutlined, DeleteOutlined, InfoOutlined, DownloadOutlined } from '@ant-design/icons';
import { PDFDownloadLink, Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import { ColorRing } from 'react-loader-spinner';
import { Popover, Input, Select } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import '../../src/Userlist.css';
import { API_URL } from '../ApiUrl';

function Userlist() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [csvData, setCsvData] = useState([]);
  const [detailData, setDetailData] = useState(null);
  const [pdfContent, setPdfContent] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);


  useEffect(() => {
    fetchData();
  }, [currentPage, searchQuery]);

  useEffect(() => {
    formatCsvData();
  }, [data]);

  const fetchData = async () => {
    try {
      const apiUrl = `${API_URL}/user/getallUser?page=${currentPage}&limit=${itemsPerPage}&search=${searchQuery}`;
      const response = await fetch(apiUrl);
      const result = await response.json();

      if (response.ok) {
        setData(result.data);
      } else {
        console.error('Failed to fetch data:', result.error);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const formatCsvData = () => {
    const formattedData = data.map(row => ({
      "First Name": row.first_name,
      "Last Name": row.last_name,
      "Role": row.role,
      "Mobile Number": row.mobile,
      // "Booking ID": row.booking_id,
      // "Time of Booking": row.time_of_booking,
      // "category": row.category,
      "Status": row.status ? "Active" : "Inactive"
    }));

    setCsvData(formattedData);
  };


  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 12,
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 40,
    },
    section: {
      marginBottom: 10,
    },
    header: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 10,
    },
    text: {
      marginBottom: 5,
    },
  });
  
  const generatePdfContent = (rowData) => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>Personal Information</Text>
          <Text style={styles.text}>First Name: {rowData.first_name}</Text>
          <Text style={styles.text}>Last Name: {rowData.last_name}</Text>
          <Text style={styles.text}>Role: {rowData.role}</Text>
          <Text style={styles.text}>Mobile Number: {rowData.mobile}</Text>
        </View>
      </Page>
    </Document>
  );

  const handleColumnFilter = (columnName, value) => {
    setCurrentPage(1); 
    setSearchQuery(''); 
    if (columnName === 'status') {
      if (value === 'all') {
        fetchData();
      } else {
        const filteredData = data.filter((row) => {
          if (value === 'active') {
            return row.status === true;
          } else if (value === 'inactive') {
            return row.status === false;
          }
          return true; 
        });
        setData(filteredData);
      }
    } else if (columnName === 'role') {
      if (value === 'all') {
        fetchData();
      } else {
        const filteredData = data.filter((row) => {
          if (value === 'User') {
            return row.user === true;
          } else if (value === 'Venue Admin') {
            return row.venueadmin === false;
          }
          return true; 
        });
        setData(filteredData);
      }
    } else {
      // Handle filtering for other columns if needed
      const filteredData = data.filter((row) =>
        row[columnName].toLowerCase().includes(value.toLowerCase())
      );
      setData(filteredData);
    }

    setIsPopoverOpen(false); // Close popover after filtering
  };

  const { Option } = Select;



  const handlePdf = async (row) => {
    console.log('Info clicked for row:', row);
    try {
      // Fetch detail data
      const detailResponse = await fetch(`${API_URL}/user/fetch-user-by-id/${row._id}`);
      const detailResult = await detailResponse.json();
      if (detailResponse.ok) {
        setDetailData(detailResult.data);
        setPdfContent(generatePdfContent(detailResult.data));
      } else {
        console.error('Failed to fetch detail data:', detailResult.error);
      }
    } catch (error) {
      console.error('Error fetching detail data:', error);
    }
  };



  const handleInfo = async (row) => {
    console.log('Info clicked for row:', row);
    try {
      // Fetch detail data
      const detailResponse = await fetch(`${API_URL}/user/fetch-user-by-id/${row._id}`);
      const detailResult = await detailResponse.json();
      if (detailResponse.ok) {
        setDetailData(detailResult.data);
      } else {
        console.error('Failed to fetch detail data:', detailResult.error);
      }
    } catch (error) {
      console.error('Error fetching detail data:', error);
    }
  };



  const handleEdit = async (row) => {
    console.log('Edit clicked for row:', row);
    try {
      const response = await fetch(`${API_URL}/super-admin/update-user/${row._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({})
      });

      if (response.ok) {
        console.log('User name updated successfully');
      } else {
        const responseData = await response.json();
        console.error('Failed to update User name:', responseData.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Error updating User name:', error);
    }
  };

  const handleDelete = async (row) => {
    try {
      const apiUrl = `${API_URL}/user/delete/${row._id}`;

      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        Swal.fire('Deactivated!', 'Your Profile has been Inactivated.', 'success');
        fetchData();
      } else {
        console.error('Failed to delete User:', response.statusText);
        Swal.fire('Error', 'Failed to delete User.', 'error');
      }
    } catch (error) {
      console.error('Error deleting User:', error);
      Swal.fire('Error', 'An error occurred while deleting the User.', 'error');
    }
  };

  const handleSearch = () => {
    setSearchQuery(searchText);
    setCurrentPage(1);
  };

  const handleSearchInputChange = async (e) => {
    setSearchText(e.target.value);
    try {
      const apiUrl = `${API_URL}/user/getallUser?page=${currentPage}&limit=${itemsPerPage}&search=${e.target.value}`;
      const response = await fetch(apiUrl);
      const result = await response.json();
      if (response.ok) {
        setData(result.data);
        console.log(e.target.value, " ye search api chali hai", data);
      } else {
        console.error('Failed to fetch data:', result.error);
      }

      setLoading(false);
    } catch (err) {
      console.log("error ka message", err.message);
    }
  };

  const handlePagination = (page, pageSize) => {
    setCurrentPage(page);
  };
  console.log("Total items:", data.length);



  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data
    .filter((row) => row.first_name.toLowerCase().includes(searchText.toLowerCase()))
    .slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <h3 className="mb-4 title">Users</h3>
      <div className="cnt">
        <Form.Group as={Row} className="mb-3 align-items-center">
          <Col sm={6}>
            <Form.Control
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchInputChange}
              className='search-input'
            />
          </Col>
          <Col sm={6} className="d-flex justify-content-end align-items-center">
            <div>
              <Link to="/users/add">
                <button className="add-button">Add User</button>
              </Link>
              <CSVLink data={csvData} filename={"user_list.csv"}>
                <button
                  className="down-button"
                >
                  Download
                </button>
              </CSVLink>
            </div>
          </Col>
        </Form.Group>
        <div className="table-container">
          {loading ? (
            <div className="text-center">
              <ColorRing
                visible={true}
                height="50"
                width="50"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
              />
              <p>Loading...</p>
            </div>
          ) : (
            <Table className="custom-table">
              <thead>
                <tr>
                  <th style={{ width: '7%' }}>S.No.</th>
                  <th style={{ width: '10%' }}>
                    First Name{' '}
                    <Popover
                      placement="bottom"
                      title="Filter by First Name"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('first_name', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>
                  <th style={{ width: '10%' }}>
                    Last Name{' '}
                    <Popover
                      placement="bottom"
                      title="Filter by Last Name"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('last_name', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>
                  <th style={{ width: '10%' }}>
                    Role{' '}
                    <Popover
                      placement="bottom"
                      title="Filter by Role"
                      content={
                        <Select
                          placeholder="Select role"
                          onChange={(value) => handleColumnFilter('role', value)}
                          style={{ width: 190 }}
                        >
                          <Option value="all">All</Option>
                          <Option value="user">User</Option>
                          <Option value="venue_admin">Venue Admin</Option>
                          {/* Add more options as needed */}
                        </Select>
                      }
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>

                  <th style={{ width: '10%' }}>Mobile Number</th>
                  {/* Add more columns as needed */}
                  <th style={{ width: '10%' }}>
                    Status{' '}
                    <Popover
                      placement="bottom"
                      content={
                        <Select
                          placeholder="Select status"
                          onChange={(value) => handleColumnFilter('status', value)}
                          style={{ width: 190 }}
                        >
                          <Option value="all">All</Option>
                          <Option value="active">Active</Option>
                          <Option value="inactive">Inactive</Option>
                        </Select>
                      }
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>

                  <th style={{ width: '10%' }}>Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentItems.map((row, index) => (
                  <React.Fragment key={row._id}>
                    <tr>
                      <td>{index + 1 + indexOfFirstItem}</td>
                      <td>{row.first_name ? row.first_name : "NA"}</td>
                      <td>{row.last_name ? row.last_name : "NA"}</td>
                      <td>{row.role ? row.role : "NA"}</td>
                      <td>{row.mobile ? row.mobile : "NA"}</td>
                      {/* <td>{row.booking_id ? row.booking_id : "NA"}</td>
                      <td>{row.time_of_booking ? row.time_of_booking : "NA"}</td>
                      <td>{row.category ? row.category : "NA"}</td> */}
                      <td style={{ color: row.status ? "#4fd104" : "#ff0000", fontWeight: "bold" }}>
                        {row.status ? "Active" : "Inactive"}
                      </td>

                      <td>
                        <div style={{ display: 'flex' }}>

                          <PDFDownloadLink
                            document={generatePdfContent(row)}
                            fileName={`user_details.pdf`}
                            style={{marginRight: '5%'}}
                          >
                            <DownloadOutlined
                              style={{
                                fontSize: '20px',
                                color: '#fcfcfa',
                                borderRadius: '5px',
                                padding: '5px',
                                backgroundColor: '#ff5f15',
                              }}
                              onClick={() => handlePdf(row)}
                            />
                          </PDFDownloadLink>


                          <Tooltip
                            title={
                              <span style={{ whiteSpace: 'pre-line' }}>
                                {`Full Name: ${row.first_name ? row.first_name : "NA"} ${row.last_name ? row.last_name : "NA"}\nRole: ${row.role ? row.role : "NA"}\nEmail: ${row.email ? row.email : "NA"}\nMobile Number: ${row.mobile ? row.mobile : "NA"}`}
                              </span>
                            }
                            arrow
                          >
                            <InfoOutlined
                              style={{
                                fontSize: '20px',
                                color: '#fcfcfa',
                                borderRadius: '5px',
                                padding: '5px',
                                backgroundColor: '#ff5f15',
                                cursor: 'pointer', // Add cursor
                                marginRight: '5%'
                              }}
                              onClick={() => handleInfo(row)}
                            />
                          </Tooltip>
                          <Link to={`/UpdateUser/${row._id}`} style={{ marginLeft: '1%' }}>
                            <EditOutlined
                              style={{
                                fontSize: '20px',
                                color: '#fcfcfa',
                                borderRadius: '5px',
                                padding: '5px',
                                backgroundColor: '#3d9c06',
                              }}
                              onClick={() => handleEdit(row)}
                            />
                          </Link>
                          {/* <Tooltip title="Deactivate">  */}
                          <DeleteOutlined
                            style={{
                              fontSize: '20px',
                              color: '#E7F3FF',
                              borderRadius: '5px',
                              padding: '5px',
                              backgroundColor: '#ff5f15',
                              marginLeft: '5px',
                            }}
                            onClick={() => handleDelete(row)}
                          />
                          {/* </Tooltip> */}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          )}
        </div>
        {/* <div className="pagination-container">
          <ul className="pagination">
            {Array.from({ length: Math.ceil(data.length / itemsPerPage) }).map((_, index) => (
              <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePagination(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </div> */}
        <Pagination
          pageSizeOptions={["5", "10", "20", "50"]} // Available page sizes
          showSizeChanger={true} // Show the page size changer dropdown
          showQuickJumper={true} // Show quick jumper
          total={data.length} // Total number of items
          pageSize={itemsPerPage} // Items per page
          current={currentPage} // Current page
          onChange={handlePagination}
          onShowSizeChange={(current, size) => {
            setCurrentPage(1); // Reset to first page when changing page size
            setItemsPerPage(size); // Update items per page
          }}
        />
      </div>
    </>
  );
}

export default Userlist;
