import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import Multiselect from 'multiselect-react-dropdown';
import axios from "axios";
import { useParams } from "react-router-dom";
import { API_URL } from '../ApiUrl';
import { Image_URL } from '../ApiUrl'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { FiUpload, FiX } from 'react-icons/fi';
import '../../src/Venue.css';

const UpdateVenue = () => {
  var { _id } = useParams();

  const [input, setInput] = useState({
    name: "",
    address: "",
    state: "",
    zipcode: "",
    city: "",
    images: [],
    amenities: [],
    category: "Select Category",
    activities: [],
    status: "",
  });

  const amenitiesOptions = [
    { name: 'Select' },
    { name: 'Parking' },
    { name: 'Washroom' },
    { name: 'Drinking Water' },
    { name: 'Flood Lights' },
    { name: 'Artificial Turf' },
    { name: 'Seating Lounge' },
    { name: 'Changing Room' },
    { name: 'Power Backup' },
    { name: 'Open 24x7' },
  ];

  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [activities, setActivities] = useState([]);
  const [newFile, setNewFile] = useState({ new_images: [] });
  const [filePreview, setFilePreview] = useState();

  useEffect(() => {
    fetchCategories();
    fetchActivities();
    fetchVenue();
  }, []);

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    setNewFile(prevState => ({
      ...prevState,
      new_images: [...prevState.new_images, ...files],
    }));
  };

  const handlePreviousImages = (e) => {
    const files = Array.from(e.target.files);
    setNewFile(prevState => ({
      ...prevState,
      images: [...prevState.images, ...files],
    }));
  };

  const handleRemovePreviousImages = (ele) => {
    setInput(prevState => ({
      ...prevState,
      images: prevState.images.filter((_, i) => i !== ele),
    }));
  };

  const handleRemovePhoto = (index) => {
    setNewFile(prevState => ({
      ...prevState,
      new_images: prevState.new_images.filter((_, i) => i !== index),
    }));
  };


  const uploadImage = async (fileArray) => {
    console.log('data before sending..................', fileArray)
    try {
      // formData.append("uploadFile", image);
      const formData = new FormData();
      fileArray.forEach((newFile, index) => {
        formData.append(`uploadFile`, newFile); // Append each file directly
      });
      const response = await axios.post(
        `${API_URL}/upload-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      // console.log(response, "response")
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return null;
    }
  };

  const handleAmenitiesChange = (selectedList) => {
    setInput({ ...input, amenities: selectedList });
  };

  const handleCategoryChange = (selectedOption) => {
    setInput({ ...input, category: selectedOption.label });
  };


  const handleActivityChange = (selectedOptions) => {
    const selectedActivities = selectedOptions.map(option => option.label);
    setInput({ ...input, activities: selectedActivities });
  };
  

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/category/fetch`);
      setCategories(response.data.categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchActivities = async () => {
    try {
      const response = await axios.get(`${API_URL}/activity/fetch`);
      setActivities(response.data.activities);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };




  const fetchVenue = async () => {
    try {
      const response = await axios.get(`${API_URL}/venue/individual/${_id}`);
      const venue = response.data.venue;
      console.log(response.data.venue, ">>>>>DATA")
      console.log("venue  data..", venue);
      setInput({
        name: venue.name,
        address: venue.address,
        state: venue.state,
        zipcode: venue.zipcode,
        city: venue.city,
        amenities: venue.amenities,
        category: venue.category,
        activities: venue.activities,
        images: venue.images,
        status: venue.status
      });
      console.log("amenities-->", venue.amenities)
      console.log("activities->", venue.activities)
      console.log("category->", venue.category)
      if (venue.images.length > 0) {
        const imageUrl = venue.images;
        setFilePreview(imageUrl);

      }
    } catch (error) {
      console.error("Error fetching venue data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleCancel = () => {
    navigate('/venues');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (input.name.trim() === '') {
      Swal.fire({
        title: "Validation Error!",
        text: "Venue name is required",
        icon: "error"
      });
    } else {
      try {
        // Upload images first
        const uploadResponses = await uploadImage(newFile.new_images);
        if (uploadResponses) {
          const mergedImages = input.images.concat(uploadResponses.data.file_data);
          const formData = new FormData();
          mergedImages.forEach(image => {
            formData.append('images', image);
          });
          uploadResponses.data.file_data.map((ele) => {
            console.log(ele, "all eleeeeeeee")
            formData.append('images', ele);
          })

          const response = await axios.put(`${API_URL}/venue/edit/${_id}`,
            {
              'name': input.name,
              'address': input.address,
              'state': input.state,
              'zipcode': input.zipcode,
              'city': input.city,
              'amenities': input.amenities,
              'category': input.category,
              'activities': input.activities,
              'images': mergedImages
            }
          );
          Swal.fire({
            title: "Submitted!",
            text: "Venue updated successfully!",
            icon: "success"
          }).then(() => {
            window.location.href = "/venues"; // Redirect to venues page after successful update
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "Failed to upload one or more images",
            icon: "error"
          });
        }
      } catch (error) {
        console.error("Error updating venue:", error);
        Swal.fire({
          title: "Error!",
          text: "Failed to update venue",
          icon: "error"
        });
      }
    }
  };

  return (
    <>
      <h3 className="mb-4 title">Update Venue</h3>
      <Container>
        <Row>
          <Col md={4}>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Venue Name"
                  name="name"
                  value={input.name}
                  onChange={handleChange}
                />
              </Form.Group>

              

              <Form.Group controlId="formTimings">
                <Form.Label className="heading">
                  Amenities
                </Form.Label>
                <div className="select-wrapper">
                  <Multiselect
                    options={amenitiesOptions}
                    selectedValues={input.amenities}
                    onSelect={handleAmenitiesChange}
                    onRemove={handleAmenitiesChange}
                    displayValue="name"
                  />
                </div>
              </Form.Group>

              <Form.Group controlId="formCategory">
                <Form.Label className="heading">
                  Category
                </Form.Label>
                <Select
                  name="category"
                  value={input.category}
                  options={categories.map((category) => ({
                    label: category.category_name,
                    value: category.category_name,
                  }))}
                  onChange={handleCategoryChange}
                  placeholder={input.category}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={4}>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Address"
                name="address"
                value={input.address}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formLocation">
              <Form.Label className="heading">Zipcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Zipcode"
                name="zipcode"
                value={input.zipcode}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formActivity">
              <Form.Label className="heading">
                Activities
              </Form.Label>
              <Select
                name="activities"
                value={input.activities.map(activity => ({ label: activity, value: activity }))}
                options={activities.map((activity) => ({
                  label: activity.activity_name,
                  value: activity.activity_name,
                }))}
                onChange={handleActivityChange}
                isMulti  // Add this prop for multiple selection
                placeholder="Select Activities"
              />

            </Form.Group>
          </Col>

          <Col md={4}>
            {/* <h6>State</h6>
            <StateSelect
              countryid={countryid}
              value={input.state}
              onChange={(e) => {
                setInput({ ...input, state: e.name });
                setstateid(e.id);
              }}
              placeHolder="Select State"
            /> */}
            {/* <h6>City</h6>
            <CitySelect
              countryid={countryid} 
              stateid={stateid}
              value={input.city}
              onChange={(e) => {
                setInput({ ...input, city: e.name });
              }}
              placeHolder="Select City"
            /> */}

            <div className="mb-3">
              <h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Upload Photo</h6>
              <div
                onDrop={(e) => {
                  e.preventDefault();
                  const files = Array.from(e.dataTransfer.files);
                  setInput(prevState => ({
                    ...prevState,
                    images: [...prevState.images, ...files.filter(file => file.type.startsWith('image/'))],
                  }));
                }}
                onDragOver={(e) => e.preventDefault()}
                style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center', width: '300px' }}
              >
                <h3 style={{ fontSize: '18px' }}>Drag & Drop here</h3>
                <div style={{ marginBottom: '10px' }}>
                  <FiUpload style={{ fontSize: '48px', marginBottom: '10px' }} />
                  <input type="file" multiple onChange={handleFileInputChange} style={{ display: 'none' }} />
                  <button className='btn3' onClick={() => document.querySelector('input[type=file]').click()}> Or Click to Select </button>
                </div>
                <div>

                  {filePreview && (
                    <div>
                      {filePreview.map((ele, index) => {
                        return (
                          <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                            {/* {console.log(`http://localhost:4000/${ele.src}`, "imgggggggggg")} */}
                            <img src={`${Image_URL}/${ele.src}`} alt="Selected Photo" style={{ width: '100px', height: '100px', margin: '5px' }} />
                            <button
                              onClick={() => {
                                handleRemovePreviousImages(index)
                                setFilePreview(() => filePreview.filter((ele2) => {
                                  return ele2 !== ele;
                                }));
                              }}
                              style={{ position: 'absolute', top: '5px', right: '5px', background: 'none', border: 'none', cursor: 'pointer' }}
                            >
                              <FiX />
                            </button>
                          </div>
                        )
                      })
                      }
                    </div>
                  )}
                  {newFile.new_images.map((photo, index) => (
                    <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                      <img src={URL.createObjectURL(photo)} alt={`Photo ${index}`} style={{ width: '100px', height: '100px', margin: '5px' }} />
                      <button
                        onClick={() => handleRemovePhoto(index)}
                        style={{ position: 'absolute', top: '5px', right: '5px', background: 'none', border: 'none', cursor: 'pointer' }}
                      >
                        <FiX />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

          </Col>

          <Form.Group controlId="formCheckbox">
            <div className="checkbox-container">
              <Form.Check
                type="checkbox"
                id="statusCheckbox"
                name="status"
                aria-label="option 1"
                className="checkbox-input"
                checked={input.status}
                onChange={e => setInput({ ...input, status: e.target.checked })}
              />
            </div>
            <Form.Label className="checkbox-label">Status</Form.Label>
          </Form.Group>
        </Row>
        
            <button
              type="submit"
              onClick={handleSubmit}
              className="SubmitButton"
            >
              Submit
            </button>
          
          
            <button
              type="cancel"
              className="CancelButton"
              onClick={handleCancel}
            >
              Cancel
            </button>
          
      </Container>
    </>
  );
};

export default UpdateVenue;
