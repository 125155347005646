import React, { useState, useEffect } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { Table, DatePicker } from "antd";
// import { BarChart } from '@mui/x-charts/BarChart';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import axios from "axios";
import { API_URL } from '../ApiUrl';
// import '../../src/Dashboard.css'
const { RangePicker } = DatePicker;


const columns = [
  {
    title: "S.No",
    dataIndex: "key",
    render: (_, __, index) => index + 1,
  },
  {
    title: "First Name",
    dataIndex: "first_name",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
  },
  {
    title: "Mobile",
    dataIndex: "mobile",
  },
  {
    title: "Booking Time",
    dataIndex: "key",
    // render: (_, __, index) => index + 1,
  },
  {
    title: "category",
    dataIndex: "category",
  },
  {
    title: "Booking ID",
    dataIndex: "Booking ID",
  },
  {
    title: "Role",
    dataIndex: "role",
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: date => {
      const formattedDate = new Date(date);
      const day = formattedDate.getDate();
      const month = formattedDate.getMonth() + 1;
      const year = formattedDate.getFullYear();
      return `${day}-${month}-${year}`;
    }
  },
  {
    title: "Booking",
    dataIndex: "status",
    render: (status) => (status ? "Accepted" : "Pending"),
  }
];


const visitor = [
  {
    title: 'S.No',
    dataIndex: 'key',
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: date => {
      const formattedDate = new Date(date);
      const day = formattedDate.getDate();
      const month = formattedDate.getMonth() + 1;
      const year = formattedDate.getFullYear();
      return `${day}-${month}-${year}`;
    }
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Mobile Number',
    dataIndex: 'mobile',
  },
];


const data = [
  { name: 'Jan', OldUser: 3, NewUser: 4, All: 10 },
  { name: 'Feb', OldUser: 4, NewUser: 3, All: 6 },
  { name: 'Mar', OldUser: 1, NewUser: 1, All: 5 },
  { name: 'Apr', OldUser: 6, NewUser: 5, All: 8 },
  { name: 'May', OldUser: 5, NewUser: 8, All: 9 },
  { name: 'Jun', OldUser: 5, NewUser: 8, All: 9 },
  { name: 'Jul', OldUser: 5, NewUser: 8, All: 9 },
  { name: 'Aug', OldUser: 5, NewUser: 8, All: 19 },
  { name: 'Sep', OldUser: 5, NewUser: 8, All: 9 },
  { name: 'Oct', OldUser: 5, NewUser: 8, All: 9 },
  { name: 'Nov', OldUser: 5, NewUser: 8, All: 9 },
  { name: 'Dec', OldUser: 5, NewUser: 8, All: 9 },

];



const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [venueCount, setVenueCount] = useState(0);
  const [eventCount, setEventCount] = useState(0);
  const [currentDate, setCurrentDate] = useState("");
  const [visitorData, setVisitorData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [userData, setUserData] = useState([]);


  useEffect(() => {
    // Fetch today's date
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
    setCurrentDate(formattedDate);

    // Fetch counts from API
    axios.get(`${API_URL}/dashboard/count`)
      .then((response) => {
        setUserCount(response.data.userCount);
        setVenueCount(response.data.venueCount);
        setEventCount(response.data.eventCount);
      })
      .catch((error) => {
        console.error("Error fetching counts:", error);
      });




    // Old users and New users count....
    axios.get(`${API_URL}/user/getallUser`)
      .then((response) => {
        const userData = response.data.data;

        // Group users by month
        const userCountsByMonth = {};
        for (let i = 0; i < 12; i++) {
          const monthName = new Date(2024, i, 1).toLocaleString('default', { month: 'short' });
          userCountsByMonth[monthName] = 0;
        }

        userData.forEach(user => {
          const createdAt = new Date(user.createdAt);
          const monthName = createdAt.toLocaleString('default', { month: 'short' });
          userCountsByMonth[monthName]++;
        });

        // Update userData state to include counts for each month
        const data = Object.keys(userCountsByMonth).map(month => ({
          name: month,
          OldUser: userCountsByMonth[month],
          NewUser: userCountsByMonth[month],
          All: userCountsByMonth[month],
        }));

        // Set the data for the graph
        setUserData(data);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });



    // Fetch new user and old user data
    axios.get(`${API_URL}/user/getNewAndOldUser`)
      .then((response) => {
        const { newUserCount, oldUserCount } = response.data;

        // Update the chart data
        const updatedUserData = userData.map(item => {
          if (item.type === 'New User') {
            return { ...item, sales: newUserCount };
          } else if (item.type === 'Old User') {
            return { ...item, sales: oldUserCount };
          }
          return item;
        });

        // Set the updated data for the graph
        setUserData(updatedUserData);
      })


    // VISITOR...
    axios.get(`${API_URL}/dashboard/fetch-visitors`)
      .then(response => {
        const formattedVisitorData = response.data.data.map((item, index) => ({
          ...item,
          key: index + 1
        }));
        console.log(formattedVisitorData, ">>>>>>>>>>>>>>>>>>>VISITOR DATA")
        setVisitorData(formattedVisitorData);
      })
      .catch(error => {
        console.error('Error fetching visitor data:', error);
      });


    // Booking.....
    axios
      .get(`${API_URL}/user/getallUser`)
      .then((response) => {
        console.log(response.data.data, ">>>>>>>>>>>>>>>>>>BOOKING DATA");
        setBookingData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching booking data:", error);
      });


    // Update counts and current date every day
    const interval = setInterval(() => {
      const today = new Date();
      const formattedDate = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
      setCurrentDate(formattedDate);
      axios.get(`${API_URL}/dashboard/count`)
        .then((response) => {
          setUserCount(response.data.userCount);
          setVenueCount(response.data.venueCount);
          setEventCount(response.data.eventCount);
          console.log(response);
        })
        .catch((error) => {
          console.error("Error fetching counts:", error);
        });
    }, 86400000); // Update every 24 hours

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, []);



  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
    // console.log(dates, "<<<<<<<<<<<<<<<< Date");
  };


  // Update config object to use month names on X-axis
  const config = {
    data: userData,
    xField: "type",
    yField: "sales",
    color: "#ff5f15",
    label: { position: "middle", style: { fill: "#FFFFFF", opacity: 1 } },
    xAxis: { label: { autoHide: true, autoRotate: false } },
    meta: { type: { alias: "Month" }, sales: { alias: "New users" } },
  };

  return (
    <div>
      {/* <div className="d-flex justify-content-between align-items-center gap-3 mb-3">
        <div onClick={() => navigate(-2)}>
        <FaArrowLeft size={30} />
        </div>
        </div> */}
      <h3 className="mb-4 title">Dashboard</h3>
      <div className="d-flex justify-content-between align-items-center gap-3 ">
        {/* Total User */}
        <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
          <div>
            <p className="desc">Total User</p>
            <h4 className="mb-0 sub-title">{userCount}</h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <h6>
              <BsArrowUpRight /> 5%
            </h6>
            <p className="mb-0 desc"> {currentDate}</p>
          </div>
        </div>
        {/* Total Venue */}
        <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
          <div>
            <p className="desc">Total Venue</p>
            <h4 className="mb-0 sub-title">{venueCount}</h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <h6 className="red">
              <BsArrowUpRight /> 5%
            </h6>
            <p className="mb-0 desc"> {currentDate}</p>
          </div>
        </div>
        {/* Total Event */}
        <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
          <div>
            <p className="desc">Total Event</p>
            <h4 className="mb-0 sub-title">{eventCount}</h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <h6 className="green">
              <BsArrowUpRight /> 5%
            </h6>
            <p className="mb-0 desc">{currentDate}</p>
          </div>
        </div>
      </div>
      {/* Registration */}
      <div className="mt-4">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3 className="mb-4 title">Registration</h3>
          <div>
            <RangePicker onChange={handleDateRangeChange} />
          </div>
        </div>
        <div>
          <BarChart width={1200} height={350} data={data}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="OldUser" stackId="a" fill="#3d9c06" />
            <Bar dataKey="NewUser" stackId="a" fill="#afd777" />
            <Bar dataKey="All" fill="#ff5f15" />
          </BarChart>


          {/* <BarChart
            series={[
              { data: userData.map(item => item.type === 'Old User' ? item.sales : 0), stack: 'A', label: 'Old User' },
              { data: userData.map(item => item.type === 'New User' ? item.sales : 0), stack: 'A', label: 'New User' },
              { data: userData.map(item => item.sales), label: 'All Users' },
            ]}
            width={1200}
            height={350}
          /> */}

        </div>
      </div>
      {/* Recent Booking */}
      <div className="mt-4">
        <h3 className="mb-5 title">Recent Booking</h3>
        {/* <div>
          <Table columns={columns} dataSource={bookingData} />
        </div> */}
        <div>
          <Table
            columns={columns}
            dataSource={bookingData}
            pagination={{
              pageSizeOptions: ["5", "10", "20", "50"], // Available page sizes
              showSizeChanger: true, // Show the page size changer dropdown
              showQuickJumper: true, // Show quick jumper
            }}
          />
        </div>
      </div>
      {/* Recent Visitor */}
      <div className="mt-4">
        <h3 className="mb-5 title">Recent Visitor</h3>
        {/* <div>
      <Table columns={visitor} dataSource={visitorData} />
      </div> */}
        <div>
          <Table
            columns={visitor}
            dataSource={visitorData}
            pagination={{
              pageSizeOptions: ["5", "10", "20", "50"], // Available page sizes
              showSizeChanger: true, // Show the page size changer dropdown
              showQuickJumper: true, // Show quick jumper
            }}
          />
        </div>
      </div>

    </div>
  );
};

export default Dashboard;