import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { StateSelect, CitySelect, LanguageSelect } from "react-country-state-city";
// import ProfilePicture from "@dsalvagni/react-profile-picture"
// import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css"
import { useNavigate } from "react-router-dom";
import { FiUpload, FiX } from 'react-icons/fi';
import Swal from "sweetalert2";
import axios from "axios";
import { API_URL } from '../ApiUrl';
import '../Coaches.css';

const Coaches = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    gender: "",
    age: "",
    languages: "",
    email: "",
    mobile: "",
    location: {
      address: "",
      city: "",
      state: "",
      zipcode: ""
    },
    experience: "",
    availability: "",
    specializations: [],
    profile_picture: [],
    // gallery: [],
    bio: "",
    status: true,
  });

  useEffect(() => {
    setCountryid(101);
  }, []);


  const navigate = useNavigate();
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [languages, setlanguages] = useState(0);

  const handleChange = (e) => {
    console.log("data in handle change", e)
    if (e.name === "zipcode" || e.name === "address") {
      setFormData({
        ...formData, location: {
          ...formData.location,
          [e.name]: e
        }
      });
    } else {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    console.log("Form Data Updated:", formData);
  };

  const handleStateChange = (state) => {
    setFormData({
      ...formData,
      state: state,
    });
    console.log("State Updated:", state);
  };

  const handleCityChange = (city) => {
    setFormData({
      ...formData,
      city: city.name,
    });
    console.log("City Updated:", city);
  };


  const handleLanguageChange = (city) => {
    setFormData({
      ...formData,
      languages: languages.name,
    });
    console.log("languages Updated:", languages);
  };

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData(prevState => ({
      ...prevState,
      profile_picture: [...prevState.profile_picture, ...files],
    }));
  };

  const handleRemovePhoto = (index) => {
    setFormData(prevState => ({
      ...prevState,
      profile_picture: prevState.profile_picture.filter((_, i) => i !== index),
    }));
  };


  const uploadImage = async (fileArray) => {
    // console.log('data before sending', fileArray)
    try {
      // formData.append("uploadFile", image);
      const formData1 = new FormData();
      fileArray.forEach((file, index) => {
        formData.append(`uploadFile`, file); // Append each file directly
      });
      const response = await axios.post(
        `${API_URL}/upload-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      // console.log(response, "response")
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return null;
    }
  };


  const handleCancel = () => {
    navigate('/coaches');
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const uploadResponses = await uploadImage(formData.profile_picture);
      console.log("image ka data retuen ", uploadResponses)
      let imageArr = []
      if (uploadResponses && uploadResponses.data.file_data.length > 0) {
        uploadResponses.data.file_data.map((ele) => {
          console.log(ele, "all eleeeeeeee")
          // formData.append('images', ele);
          imageArr.push(ele);
        })
      }
      formData.profile_picture = imageArr

      const response = await axios.post(
        `${API_URL}/create-coach`,
        formData,
        {
          headers: {
            "Content-Type": 'application/json'
          },
        }
      )

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Coach added successfully",
        });
        navigate("/coaches")
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.message,
        });
      }


    } catch (error) {
      console.error("Error:", error);
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Failed to add coach",
      // });
    }
  };

  return (
    <>
      <h3 className="mb-4 title">Coach</h3>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={3}>
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group controlId="formGender">
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group controlId="formAge">
                <Form.Label>Age</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter age"
                  name="age"
                  value={formData.age}
                  onChange={handleChange}
                />
              </Form.Group><br></br>
            </Col>
          </Row>
          <Row>

            <Col sm={3}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group><br></br>
            </Col>

            <Col sm={3}>
              <Form.Group controlId="formMobile">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter mobile number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group controlId="formAvailability">
                <Form.Label>Availability</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter availability"
                  name="availability"
                  value={formData.availability}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group controlId="formSpecializations">
                <Form.Label>Specializations</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter specializations"
                  name="specializations"
                  value={formData.specializations}
                  onChange={handleChange}
                />
              </Form.Group><br></br>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <h6>Language</h6>
              <LanguageSelect
                autoComplete="off"
                value={formData.languages}
                onChange={(e) => {
                  setFormData({ ...formData, languages: e.name });
                  setlanguages(e.id);
                  console.log("Form Data Updated:", formData);
                }}
                placeHolder="Select Language"
              />
            </Col>
            <Col sm={3}>
              <h6>State</h6>
              <StateSelect
                countryid={countryid}
                value={formData.location.state}
                autoComplete="off"
                onChange={(e) => {
                  setFormData({
                    ...formData, location: {
                      ...formData.location,
                      state: e.name
                    }
                  });
                  setstateid(e.id);
                }}
                placeHolder="Select State"
              />

            </Col>
            <Col sm={3}>
              <h6>City</h6>
              <CitySelect
                autoComplete="off"
                countryid={countryid}
                stateid={stateid}
                value={formData.location.city}
                onChange={(e) => {
                  setFormData({
                    ...formData, location: {
                      ...formData.location,
                      city: e.name
                    }
                  });
                  console.log("Form Data Updated:", formData);
                }}
                placeHolder="Select City"
              />
            </Col>

            <Col sm={3}>
              <Form.Group controlId="formZipCode">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter zip code"
                  name="zipcode"
                  value={formData.location.zipcode}
                  onChange={(e) => {
                    setFormData({
                      ...formData, location: {
                        ...formData.location,
                        zipcode: e.target.value
                      }
                    });
                    console.log("Form Data Updated:", formData);
                  }}
                />
              </Form.Group><br></br>
            </Col>

            <Col sm={3}>
              <Form.Group controlId="formAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter address"
                  name="address"
                  value={formData.location.address}
                  onChange={(e) => {
                    setFormData({
                      ...formData, location: {
                        ...formData.location,
                        address: e.target.value
                      }
                    });
                    console.log("Form Data Updated:", formData);
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formExperience">
                <Form.Label>Experience (in years)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter experience"
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                />
              </Form.Group><br></br>
            </Col>

            <Col sm={3}>
              <div className="mb-3">
                <h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Upload Profile Photo</h6>
                <div
                  onDrop={(e) => {
                    e.preventDefault();
                    const files = Array.from(e.dataTransfer.files);
                    setFormData(prevState => ({
                      ...prevState,
                      profile_picture: [...prevState.profile_picture, ...files.filter(file => file.type.startsWith('profile_picture/'))],
                    }));
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center', width: '300px' }}
                >
                  <h3 style={{ fontSize: '18px' }}>Drag & Drop here</h3>
                  <div style={{ marginBottom: '10px' }}>
                    <FiUpload style={{ fontSize: '48px', marginBottom: '10px' }} />
                    <input type="file" onChange={handleFileInputChange} style={{ display: 'none' }} />
                    <button className='btn3' onClick={() => document.querySelector('input[type=file]').click()}> Or Click to Select </button>
                  </div>
                  <div>
                    {/* Upload Button */}
                    {/* <button className='btn3' onClick={fileupload}>Upload</button> */}
                    {formData.profile_picture.map((photo, index) => (
                      <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                        <img src={URL.createObjectURL(photo)} alt={`Photo ${index}`} style={{ width: '100px', height: '100px', margin: '5px' }} />
                        <button
                          onClick={() => handleRemovePhoto(index)}
                          style={{ position: 'absolute', top: '5px', right: '5px', background: 'none', border: 'none', cursor: 'pointer' }}
                        >
                          <FiX />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group controlId="formBio">
                <Form.Label>Bio</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter bio"
                  name="bio"
                  value={formData.bio}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Form.Group controlId="formCheckbox">
              <div className="checkbox-container">
                <Form.Check
                  type="checkbox"
                  id="statusCheckbox"
                  name="status"
                  aria-label="option 1"
                  className="checkbox-input"
                  checked={formData.status || false}
                  onChange={e => setFormData({ ...formData, status: e.target.checked })}
                />
              </div>
              <Form.Label className="checkbox-label">Status</Form.Label>
            </Form.Group>
          </Row>
          <button type="submit" className="SubmitButton">
            Submit
          </button>

          <button type="cancel" className="CancelButton" onClick={handleCancel}>
            Cancel
          </button>
        </Form>
      </Container>
    </>
  );
};

export default Coaches;
