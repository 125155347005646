import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DownloadOutlined, EditOutlined, DeleteOutlined, InfoOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { ColorRing } from 'react-loader-spinner';
import { CSVLink } from 'react-csv';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import { Table, Form, Row, Col, Button } from 'react-bootstrap';
//import '../../Userlist.css';
import { PDFDownloadLink, Document, Page, Text } from '@react-pdf/renderer';
import { API_URL } from '../ApiUrl';
import { Pagination, Tooltip } from 'antd';
import { Popover, Input, Select } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

function PersonalTraininglist() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(10); 
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initialize itemsPerPage state
  const [csvData, setCsvData] = useState([]);
  const [detailData, setDetailData] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [pdfContent, setPdfContent] = useState(null);


  useEffect(() => {
    fetchData();
  }, [currentPage, searchQuery]);

  useEffect(() => {
    formatCsvData();
  }, [data]);


  const fetchData = async () => {
    try {
      const apiUrl = `${API_URL}/PersonalTraining/fetchAll`;
      const response = await fetch(apiUrl);
      const result = await response.json();

      if (response.ok) {
        setData(result.data);
      } else {
        console.error('Failed to fetch data:', result.error);
      }

      setLoading(false);
    } catch (error) {
      //console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const formatCsvData = () => {
    const formattedData = data.map(row => ({
      "Trainer Name": `${row.first_name} ${row.last_name}`,
      "Duration": row.duration,
      "Focus Area": row.focus_area,
      "Price": row.price,
      "Status": row.status ? "Active" : "Inactive"
    }));
  
    setCsvData(formattedData);
  };


  const generatePdfContent = (rowData) => (
    <Document>
      <Page>
        <Text>Trainer Name: {rowData.trainer_name}</Text>
        <Text>Gender: {rowData.gender}</Text>
        <Text>Age: {rowData.age}</Text>
        <Text>Mobile Number: {rowData.mobile}</Text>
        <Text>Available: {rowData.availability}</Text>
        <Text>specializations: {rowData.Specializations}</Text>
        <Text>Language: {rowData.language}</Text>
        <Text>Experience: {rowData.experience}</Text>
        <Text>Address: {rowData.address}</Text>
        <Text>State: {rowData.state}</Text>
        <Text>City: {rowData.city}</Text>
        <Text>Zipcode: {rowData.zipcode}</Text>
      </Page>
    </Document>
  );



  const handleColumnFilter = (columnName, value) => {
    setCurrentPage(1); 
    setSearchQuery(''); 
    if (columnName === 'status') {
      if (value === 'all') {
        fetchData();
      } else {
        const filteredData = data.filter((row) => {
          if (value === 'active') {
            return row.status === true;
          } else if (value === 'inactive') {
            return row.status === false;
          }
          return true; 
        });
        setData(filteredData);
      }
    } 
    else {
      // Handle filtering for other columns if needed
      const filteredData = data.filter((row) =>
        row[columnName].toLowerCase().includes(value.toLowerCase())
      );
      setData(filteredData);
    }

    setIsPopoverOpen(false); // Close popover after filtering
  };

  
  const { Option } = Select;
  

  const handlePdf = async (row) => {
    console.log('Info clicked for row:', row);
    try {
      // Fetch detail data
      const detailResponse = await fetch(`${API_URL}/PersonalTraining/fetch/${row.id}`);
      const detailResult = await detailResponse.json();
      if (detailResponse.ok) {
        setDetailData(detailResult.data);
        setPdfContent(generatePdfContent(detailResult.data));
      } else {
        console.error('Failed to fetch detail data:', detailResult.error);
      }
    } catch (error) {
      console.error('Error fetching detail data:', error);
    }
  };


  const handleInfo = async (row) => {
    console.log('Info clicked for row:', row);
    try {
      // Fetch detail data
      const detailResponse = await fetch(`${API_URL}/PersonalTraining/fetch/${row.id}`);
      const detailResult = await detailResponse.json();
      if (detailResponse.ok) {
        setDetailData(detailResult.data);
      } else {
        console.error('Failed to fetch detail data:', detailResult.error);
      }
    } catch (error) {
      console.error('Error fetching detail data:', error);
    }
  };

  const handleEdit = async (row) => {
    try {
      const response = await fetch(`${API_URL}/PersonalTraining/fetch/${row._id}`);
      if (response.ok) {
        const data = await response.json();
        // Assuming the response data contains the necessary fields for editing
        // Update state or navigate to the edit page with the fetched data
        console.log('Fetched data for editing:', data);
      } else {
        console.error('Failed to fetch data for editing:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data for editing:', error);
    }
  };

  const handleDeactivate = async (row) => {
    try {
      // Construct the API endpoint URL for deactivating the personal training item
      const apiUrl = `${API_URL}/PersonalTraining/deactive/${row._id}`;

      // Send a POST request to the endpoint
      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        // You may optionally include a request body if required by your backend
        // body: JSON.stringify({ /* any data to be sent to the server */ }),
      });

      if (response.ok) {
        // If the response is successful, display a success message
        Swal.fire('Deactivated!', 'Personal Training has been deactivated.', 'success');
        // Fetch updated data after deactivation
        fetchData();
      } else {
        // If there's an error response, log the error message
        console.error('Failed to deactivate Personal Training:', response.statusText);
        // Display an error message to the user
        Swal.fire('Error', 'Failed to deactivate Personal Training.', 'error');
      }
    } catch (error) {
      // If an exception occurs during the request, log the error and display an error message
      console.error('Error deactivating Personal Training:', error);
      Swal.fire('Error', 'An error occurred while deactivating the Personal Training.', 'error');
    }
  };

  const handleSearch = () => {
    setSearchQuery(searchText);
    setCurrentPage(1);
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handlePagination = (page, pageSize) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data
    .filter((row) => row.trainer_name.toLowerCase().includes(searchText.toLowerCase()))
    .slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <h3 className="mb-4 title">Personal Training</h3>
      <div className="cnt">
        <Form.Group as={Row} className="mb-3">
          <Col sm={6}>
            <Form.Control
              type="text"
              className="search-input"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchInputChange}
            />
          </Col>
          <Col sm={6} className="d-flex justify-content-end align-items-center">
          <div className="mr-3">
            <Link to="/personal-traning/add">
              <button className="add-button mr-2">Add Personal Trainer</button>
            </Link>
            </div>
            <div>
            <CSVLink data={csvData} filename={"user_list.csv"}>
                <button
                  
                  className="down-button"
                >
                  Download 
                </button>
              </CSVLink>
              </div>
          </Col>
        </Form.Group>
        <div className="table-container">
          {loading ? (
            <div className="text-center">
              <ColorRing
                visible={true}
                height="50"
                width="50"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
              />
              <p>Loading...</p>
            </div>
          ) : (
            <Table className='custom-table'>
              <thead>
                <tr>
                  <th style={{ width: '7%' }}>S.No.</th>
                  <th style={{ width: '16%' }}>
                  Trainer Name{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Trainer Name"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('trainer_name', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>
                  <th style={{ width: '16%' }}>
                  Mobile Number{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Mobile Number"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('mobile', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>

                  </th>
                  <th style={{ width: '13%' }}>
                  Specialization{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Specialization"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('specialization', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>
                  <th style={{ width: '13%' }}>
                  Experience(yr){' '}
                  <Popover
                      placement="bottom"
                      title="Filter by  Experience(yr)"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('experience', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>
                  <th style={{ width: '11%' }}>
                  Location{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Location"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('location', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>

                  <th style={{ width: '10%' }}>
                  Duration{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Duration"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('duration', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>

                  <th style={{ width: '13%' }}>
                  Focus Area{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Focus Area"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('focus_area', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>

                  <th style={{ width: '15%' }}>
                  Price{' '}
                  <Popover
                      placement="bottom"
                      title="Filter by Price"
                      content={<Input placeholder="Search..." onChange={(e) => handleColumnFilter('price', e.target.value)} />}
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>

                  </th>
                  <th style={{ width: '10%' }}>
                    Status{' '}
                    <Popover
                      placement="bottom"
                      content={
                        <Select
                          placeholder="Select status"
                          onChange={(value) => handleColumnFilter('status', value)}
                          style={{ width: 190 }}
                        >
                          <Option value="all">All</Option>
                          <Option value="active">Active</Option>
                          <Option value="inactive">Inactive</Option>
                        </Select>
                      }
                      trigger="click"
                    >
                      <FilterOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                  </th>
                  <th style={{ width: '15%' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((row, index) => (
                  <tr key={row._id}>
                    <td>{index + 1 + indexOfFirstItem}</td>
                    <td>{row.trainer_name}</td>
                    <td>{row.mobile}</td>
                    <td>{row.specialization ? row.specialization : "NA"}</td>
                    <td>{row.experience ? row.experience : "NA"}</td>
                    <td>{row.location ? row.location : "NA"}</td>
                    <td>{row.duration}</td>
                    <td>{row.focus_area.join(', ')}</td>
                    <td>{row.price}</td>
                    <td style={{ color: row.status ? "#4fd104" : "#ff0000", fontWeight: "bold" }}>
                    {row.status ? "Active" : "Inactive"}
                    </td>
                    <td>
                      <div style={{ display: 'flex' }}>

                      <PDFDownloadLink
                            document={generatePdfContent(row)}
                            fileName={`personal_trainerlist_details.pdf`}
                            style={{marginRight: '5%'}}
                          >
                            <DownloadOutlined
                              style={{
                                fontSize: '20px',
                                color: '#fcfcfa',
                                borderRadius: '5px',
                                padding: '5px',
                                backgroundColor: '#ff5f15',
                              }}
                              onClick={() => handlePdf(row)}
                            />
                          </PDFDownloadLink>
                      <Tooltip title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                             {`Trainer Name: ${row.trainer_name}\nDuration: ${row.duration}\nFocus Area: ${row.focus_area.join(', ')}\nPrice: ${row.price}\nMobile No: ${row.mobile}\nSpecialization: ${row.specialization ? row.specialization : "NA"}\nExperience: ${row.experience ? row.experience : "NA"}\nLocation: ${row.location ? row.location : "NA"}`}
                             </span>
                             }
                             arrow
                             >
                            <InfoOutlined
                              style={{
                                fontSize: '20px',
                                color: '#fcfcfa',
                                borderRadius: '5px',
                                padding: '5px',
                                backgroundColor: '#ff5f15',
                                cursor: 'pointer', // Add cursor
                                marginRight: '5%'
                              }}
                              onClick={() => handleInfo(row)}
                            />
                          </Tooltip>
                        <Link to={`/personal-training/edit/${row._id}`} style={{ marginLeft: '1%' }}>
                          <EditOutlined
                            style={{
                              fontSize: '20px',
                              color: '#fcfcfa',
                              borderRadius: '5px',
                              padding: '5px',
                              backgroundColor: '#3d9c06',

                            }}
                            onClick={() => handleEdit(row)}
                          />
                        </Link>
                        {/* Change onClick event to call handleDeactivate */}
                        <DeleteOutlined
                          style={{
                            fontSize: '20px',
                            color: '#E7F3FF',
                            borderRadius: '5px',
                            padding: '5px',
                            backgroundColor: '#ff5f15',
                            marginLeft: '5px',
                          }}
                          onClick={() => handleDeactivate(row)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
        <Pagination
  pageSizeOptions={["5", "10", "20", "50"]} // Available page sizes
  showSizeChanger={true} // Show the page size changer dropdown
  showQuickJumper={true} // Show quick jumper
  total={data.length} // Total number of items
  pageSize={itemsPerPage} // Items per page
  current={currentPage} // Current page
  onChange={handlePagination} 
  onShowSizeChange={(current, size) => {
    setCurrentPage(1); // Reset to first page when changing page size
    setItemsPerPage(size); // Update items per page
  }}
/>
      </div>
    </>
  );
}

export default PersonalTraininglist;
